import * as S from "./planCard.style";
import Button from "~/components/ui/Button";
import Separator from "~/components/ui/Separator";
import { SVG } from "~/components/ui/SVG";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import { IPlan, SubscriptionType } from "~/types/billing";
import {
  centsToDecimal,
  getTotalPlanCost,
  splitNumber,
} from "~/helpers/numbers";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { triggerShowResumeModal } from "~/helpers/customEvents";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { IAccount } from "~/types/account";
import { useQueryClient } from "@tanstack/react-query";
import { PRORATION } from "~/api/billing/types";
import { Stack } from "./icons/stack";
import { Rocket } from "./icons/rocket";
import { Package } from "./icons/package";

type Props = {
  plan?: IPlan;
  isHighestPrice: boolean;
  account: IAccount | null;
  previousPlanName: string | null;
  closeModal?: () => void;
};

export const isFeatureCrossedOff = (feature: string) => {
  return feature.includes("<s>") && feature.includes("</s>");
};

const PlanCard = ({
  plan,
  account,
  closeModal,
  isHighestPrice,
  previousPlanName,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isAnnual = plan?.interval === SubscriptionType.ANNUAL;
  const bgColor = plan?.is_highlighted ? "primary" : "transparent";
  const textColor = plan?.is_highlighted ? "white" : "primary";
  const isSubscribed = plan?.id === account?.active_subscription?.plan?.id;
  const trialDays = plan?.trial_days;
  const buttonText = useMemo(() => {
    if (isSubscribed && account?.active_subscription?.paused_at)
      return t("settings.paused");

    if (isSubscribed) return t("settings.current");
    if (trialDays) return t("settings.try_for_free");
    if (isAnnual || account?.has_subscribed_before) return t("settings.start");

    return t("settings.start");
  }, [isAnnual, isSubscribed, account?.active_subscription?.plan]);

  const handleRedirect = () => {
    if (account?.active_subscription?.paused_at)
      return triggerShowResumeModal();
    localStorage.setItem("plansModal", "false");
    navigate(`/checkout/${plan?.id}`);
    queryClient.invalidateQueries(PRORATION);
    closeModal && closeModal();
  };

  const splitedTotal = splitNumber(
    centsToDecimal(plan?.cost_per_month as number).toFixed(2)
  );

  const Icon = plan?.is_highlighted ? Stack : isHighestPrice ? Rocket : Package;

  const makeSmaller = !isAnnual;

  return (
    <S.PlanCardContainer
      primary={plan?.is_highlighted || false}
      data-testid="plan-card">
      {plan?.is_highlighted ? (
        <S.PlanCardPromotion isHighlighted={plan?.is_highlighted}>
          {t("settings.recommended_plan")}
        </S.PlanCardPromotion>
      ) : null}

      <S.HeaderContainer
        isHighlighted={plan?.is_highlighted}
        makeSmaller={makeSmaller}>
        {Boolean(plan?.months_off) && (
          <S.MonthsOffContainer isHighlighted={plan?.is_highlighted}>
            <span>{t("settings.months_off", { days: plan?.months_off })}</span>
          </S.MonthsOffContainer>
        )}

        <S.Header
          isHighlighted={plan?.is_highlighted}
          makeSmaller={makeSmaller}>
          <S.IconAndPriceContainer>
            <S.IconContainer
              isHighlighted={plan?.is_highlighted}
              makeSmaller={makeSmaller}>
              <Icon />
            </S.IconContainer>

            <S.PlanCardPrice
              isHighlighted={plan?.is_highlighted}
              makeSmaller={makeSmaller}>
              {plan?.old_cost_per_month ? (
                <S.DiscountText>
                  ${centsToDecimal(plan?.old_cost_per_month)}
                </S.DiscountText>
              ) : null}
              <Trans
                i18nKey="settings.mon"
                components={{
                  1: <S.MonthlyValue makeSmaller={makeSmaller} />,
                  2: <S.DecimalValue makeSmaller={makeSmaller} />,
                }}
                values={{
                  integer: splitedTotal.integer,
                  decimal: splitedTotal.decimal,
                }}
              />
            </S.PlanCardPrice>
          </S.IconAndPriceContainer>

          <S.PlanCardTitle>{plan?.name}</S.PlanCardTitle>

          <S.PlanCardSubtitle isAnnual={isAnnual}>
            {isAnnual
              ? t("settings.billed_annually", {
                  amount: `$${getTotalPlanCost(plan)}`,
                })
              : plan?.trial_days
                ? t("settings.trial", { trial_days: plan.trial_days })
                : ""}
          </S.PlanCardSubtitle>

          <S.ButtonContainer
            isHighlighted={plan?.is_highlighted || isAnnual}
            makeSmaller={makeSmaller}>
            <Button
              size="xl"
              bgColor={bgColor}
              color={textColor}
              fontWeight={700}
              isDisabled={!!isSubscribed}
              onClick={handleRedirect}>
              {buttonText}
              <SVG icon={faArrowRight} />
            </Button>
          </S.ButtonContainer>
        </S.Header>
      </S.HeaderContainer>

      <Separator type="horizontal" className="separator" />

      <S.PlanCardList data-testid="plan-features" makeSmaller={makeSmaller}>
        <S.EverythingPlusText>
          {previousPlanName
            ? t("settings.everything_plus", { planName: previousPlanName })
            : t("settings.what_you_get")}
        </S.EverythingPlusText>

        {plan?.features.map((feature: string, i: number) => (
          <S.PlanCardItem
            key={`${feature}-${i}`}
            disabled={isFeatureCrossedOff(feature)}>
            <S.PlanCardItemIconContainer makeSmaller={makeSmaller}>
              <SVG icon={faCheck} color="green" />
            </S.PlanCardItemIconContainer>
            <S.FeatureText
              dangerouslySetInnerHTML={{ __html: feature }}
              makeSmaller={makeSmaller}
            />
          </S.PlanCardItem>
        ))}
      </S.PlanCardList>
    </S.PlanCardContainer>
  );
};

export default PlanCard;
